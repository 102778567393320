import React from "react";
import "./Arrow.css";

export default function Arrow() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // To create a smooth scroll effect
    });
  };

  return (
    <div className="arrow" onClick={scrollToTop}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="currentColor"
        className="bi bi-arrow-up-circle-fill"
        viewBox="0 0 16 16"
      >
        <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
      </svg>
    </div>
  );
}
