import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <footer className="footer">
        <p className="p-footer">
          @2023 - Designed with Figma and developed with React JavaScript by
          Astarina Maulida
        </p>
      </footer>
    </div>
  );
}
